export default function Page404() {
  return (
    <>
      <div>
        Error: <strong>404</strong>
      </div>
      <div>Такой cтраницы не существует</div>
    </>
  );
}

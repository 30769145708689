import React from "react";

export default function Home() {
  return (
    <>
      <div className="mainpage">
        <div className="page">
          <h1>Cайт-агрегатор просмотра и бронирования гостиниц</h1>
        </div>
      </div>
    </>
  );
}
